import withAuth from "HOC/WithAuth";
import { IconPeople } from "assets/icons";
import { Field, Heading } from "components";
import { ROLE } from "constants/ROLE";
import { FC } from "react";
import { useForm } from "react-hook-form";

interface IFormRegisterUser {
	name: string;
	role: string;
	team: string;
	date: string;
	passport: string;
	userImg: string;
}

const CreatePage: FC = (): JSX.Element => {
	const { register, formState } = useForm<IFormRegisterUser>();
	return (
		<div className="container">
			<Heading type="h1">Створення юзера</Heading>
			<form>
				<Field icon={<IconPeople />} placeholder="Ім’я" />
				{/* <Controller
					name="role"
					control={control}
					defaultValue={""}
					render={({ field }) => (
						<Select {...field} options={options} placeholder="Роль" />
					)}
				/> */}
			</form>
		</div>
	);
};

export default withAuth(CreatePage, ROLE.ADMIN);
