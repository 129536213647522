import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import History from "components/screens/history/History";
import LoginPage from "components/screens/login/LoginPage";
import CreatePage from "pages/admin/user/CreatePage";
import UserPage from "pages/admin/user/UserPage";
import Register from "pages/register";
import "./styles/globals.css";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
	<React.StrictMode>
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<LoginPage />} />
				<Route path="/register" element={<Register />} />
				<Route path="/user/finance" element={<History />} />
				<Route path="/admin/user/create" element={<CreatePage />} />
				<Route path="/admin/user" element={<UserPage />} />
			</Routes>
		</BrowserRouter>
	</React.StrictMode>
);
