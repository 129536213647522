import { FC } from "react";
import HistoryItem from "./HistoryItem";

// import styles from "@/history.module.scss";

const History: FC = (): JSX.Element => {
	return (
		<div className={"wd"}>
			<HistoryItem />
			daw
		</div>
	);
};

export default History;
