import { useRouter } from "hooks/useRouter";
import { FC, useEffect } from "react";
import { UserRole } from "types/user.types";

const withAuth = <P extends object>(WrappedComponent: FC<P>, role: UserRole): FC<P> => {
	const Auth: FC<P> = (props) => {
		const router = useRouter();
		// const user = useSelector((state: RootState) => state.auth.user);

		useEffect(() => {
			// if (!user) {
			if (!true) {
				router.push("/login");
				// } else if (user.role !== role) {
			} else if (4 !== role) {
				router.push("/");
			}
			// }, [user, role, router]);
		}, [router]);

		// if (!user || user.role !== role) {
		if (!true || 4 !== role) {
			return null;
		}

		return <WrappedComponent {...props} />;
	};

	return Auth;
};

export default withAuth;
