import { FC } from "react";
import { IconHistoryClaim, IconHistoryPlus } from "../../../assets/icons";

import styles from "./history.module.scss";

const HistoryItem: FC = (): JSX.Element => {
	return (
		<div className={styles.historyItem}>
			<div className={styles.icon}>
				<IconHistoryClaim />
			</div>
			<div>Заробіток</div>
			<div>
				<div className={styles.icon}>
					<IconHistoryPlus />
				</div>
				<span>21412</span>
			</div>
		</div>
	);
};

export default HistoryItem;
