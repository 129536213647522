import { FC } from "react";

const UserPage: FC = (): JSX.Element => {
	return (
		<div>
			<div>UserPage</div>
		</div>
	);
};

export default UserPage;
