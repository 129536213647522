export interface IRole {
	USER: number;
	CORDYNATOR: number;
	FINANCE: number;
	ADMIN: number;
}

export const ROLE: IRole = {
	USER: 1,
	CORDYNATOR: 2,
	FINANCE: 3,
	ADMIN: 4,
};

export const ROLES_SELECT = {
	USER: "Користувач",
	CORDYNATOR: "Кординатор",
	FINANCE: "Користувач",
	ADMIN: "Користувач",
};
