import withAuth from "HOC/WithAuth";
import { Button, Field, FileUpload, Modal, Select } from "components";
import { FC, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { ITeamOptions } from "types/team.types";

const options: ITeamOptions[] = [
	{ value: "korova", label: "korova" },
	{ value: "korova1", label: "korova2" },
	{ value: "korova2", label: "korova3" },
];

const options1: ITeamOptions[] = [
	{ value: "korova", label: "korova" },
	{ value: "korova1", label: "korova2" },
	{ value: "korova2", label: "korova3" },
];

const Register: FC = (): JSX.Element => {
	const {
		control,
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<{
		name: string;
		role: string;
		team: string;
		date: string;
	}>();

	const [isModalOpen, setIsModalOpen] = useState(false);
	const closeModal = () => {
		setIsModalOpen(false);
	};
	const openModal = () => {
		setIsModalOpen(true);
	};

	const handleForm = handleSubmit((data) => {
		console.log(data);
	});
	return (
		<div style={{ margin: "0 20px" }}>
			<Modal open={isModalOpen} onClose={closeModal} />
			<Button onClick={openModal}>Open modal</Button>
			<Button href="/login">Login</Button>
			<form onSubmit={handleForm}>
				<Field placeholder="name" {...register("name")} error={errors?.name} />
				<br />
				<Controller
					name="role"
					control={control}
					defaultValue={""}
					render={({ field }) => (
						<Select {...field} options={options} placeholder="Роль" />
					)}
				/>
				<br />
				<Controller
					name="team"
					control={control}
					defaultValue={""}
					render={({ field }) => (
						<Select {...field} options={options1} placeholder="Команда" />
					)}
				/>
				<br />
				<input type="date" {...register("date")} />
				{/* <DatePickerComponent field={register("date")} /> */}
				<br />
				<FileUpload label="Завантажте паспорт" />
				<br />
				<FileUpload label="Завантажте фотку людини" />
				<Button>register</Button>
			</form>
		</div>
	);
};

export default withAuth(Register, 1);
