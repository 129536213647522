import queryString from "query-string";
import { useMemo } from "react";
import { useLocation, useMatch, useNavigate, useParams } from "react-router-dom";

export function useRouter() {
	const params = useParams();
	const location = useLocation();
	const history = useNavigate();
	const match = useMatch("");
	return useMemo(() => {
		return {
			push: history,
			replace: () => history,
			pathname: location.pathname,
			query: {
				...queryString.parse(location.search), // Convert string to object
				...params,
			},
			match,
			location,
			history,
		};
	}, [params, match, location, history]);
}
